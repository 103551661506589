import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, DialogActions } from "@mui/material";

export default function BasicDialog({
  children,
  title,
  open,
  handleClose,
  fullScreen,
  secondaryAction,
  sx,
}) {
  return (
    <Dialog
      fullScreen={fullScreen}
      sx={{ ...sx }}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
}
