import {
  Dashboard,
  Group,
  Groups,
  Person,
  SupportAgent,
} from "@mui/icons-material";

export const navItems = [
  {
    title: "Dashboard",
    url: "/dashboard",
    icon: <Dashboard />,
  },
  {
    title: "Leads",
    url: "/leads",
    icon: <Person />,
  },
  {
    title: "Meetings",
    url: "/meetings",
    icon: <Group />,
  },
  {
    title: "Bookings",
    url: "/bookings",
    icon: <Dashboard />,
  },
  {
    title: "Users",
    url: "/users",
    icon: <SupportAgent />,
  },
  {
    title: "Teams",
    url: "/users/teams",
    icon: <Groups />,
  },
];

export const isAdminSrmanager = ["sup_admin", "sub_admin", "sr_manager"];
export const isAdmin = ["sup_admin", "sub_admin"];

export const LeaveType = [
  { value: "paid", label: "Paid" },
  { value: "unpaid", label: "Un Paid" },
];

export const LeaveTypeObj = { paid: "Paid", unpaid: "Un Paid" };

export const userTypes = {
  sup_admin: "Super Admin",
  sub_admin: "Sub Admin",
  sr_manager: "Sr Manager",
  manager: "Manager",
  assistant_manager: "Assistant Manager",
  team_lead: "Team Lead",
  agent: "Agent",
};

export const userTypeArr = [
  { value: "sr_manager", label: "Sr Manager" },
  { value: "manager", label: "Manager" },
  { value: "assistant_manager", label: "Assistant Manager" },
  { value: "team_lead", label: "Team Lead" },
  { value: "agent", label: "Agent" },
];

export const status = [
  { value: "assign", label: "Assigned" },
  { value: "no_response", label: "No Response" },
  { value: "active_cold", label: "Active Cold" },
  { value: "active_hot", label: "Active Hot" },
  { value: "meeting_scheduled", label: "Meeting Scheduled" },
  { value: "wrong_details", label: "Spam/Wrong contact details" },
  { value: "broker", label: "Broker" },
  { value: "not_interested_buy_later", label: "Not Interested May Buy Later" },
  { value: "not_able_to_connect", label: "Not Able to Connect" },
  { value: "followUp_required", label: "Follow Up Required" },
  { value: "disqualified", label: "Disqualified" },
  { value: "not_interested", label: "Not Interested" },
  { value: "deal_booked", label: "Deal Booked" },
  { value: "deal_cancelled", label: "Deal Cancelled" },
];
export const statusObj = {
  new: "New",
  assign: "Assigned",
  contacted: "Contacted",
  no_response: "No Response",
  active_cold: "Active Cold",
  active_hot: "Active Hot",
  meeting_scheduled: "Meeting Scheduled",
  wrong_details: "Spam/Wrong contact details",
  broker: "Broker",
  not_interested_buy_later: "Not Interested May Buy Later",
  not_able_to_connect: "Not Able to Connect",
  followUp_required: "Follow Up Required",
  disqualified: "Disqualified",
  not_interested: "Not Interested",
  deal_booked: "Deal Booked",
  deal_cancelled: "Deal Cancelled",
};

export const meetingStatus = {
  schedule: "Meeting Scheduled",
  conducted: "Meeting Conducted",
  reschedule: "Meeting Rescheduled",
};

export const meeting = [
  { value: "conducted", label: "Conducted" },
  { value: "schedule", label: "Schedule" },
  { value: "reschedule", label: "Reschedule" },
];

export const booking = [
  { value: "approved", label: "Approved" },
  { value: "pending", label: "Pending" },
  { value: "rejected", label: "Rejected" },
];

export const selectCatagory = [
  { value: "lead", label: "Lead" },
  { value: "meeting", label: "Meeting" },
  { value: "booking", label: "Booking" },
];

export const leadType = [
  { value: "lead", label: "Lead" },
  { value: "calling_data", label: "Calling Data" },
];

export const leadTypeObj = { lead: "Lead", calling_data: "Calling Data" };

export const developers = [
  // { value: "Kashif", label: "Kashif" },
  // { value: "Rajat", label: "Rajat" },
  { value: "AKSHARA_DEVELOPERS", label: "AKSHARA DEVELOPERS" },
  { value: "AL_ANSARI", label: "AL ANSARI" },
  { value: "AL_HABTOOR", label: "AL HABTOOR" },
  { value: "AL_SHIRAWI", label: "AL SHIRAWI" },
  { value: "ALEF_AL_MAMSHA", label: "ALEF -AL MAMSHA" },
  { value: "ARABIAN_GULF", label: "ARABIAN GULF" },
  { value: "AYS_DEVELOPERS", label: "AYS DEVELOPERS" },
  { value: "AZIZI", label: "AZIZI" },
  { value: "BINGHATTI", label: "BINGHATTI" },
  { value: "BLOOM", label: "BLOOM" },
  { value: "DAMAC", label: "DAMAC" },
  { value: "DANUBE", label: "DANUBE" },
  { value: "DEYAAR", label: "DEYAAR" },
  { value: "DI", label: "DI" },
  { value: "DUBAI_HOLDING", label: "DUBAI HOLDING" },
  { value: "DUBAI_SOUTH", label: "DUBAI SOUTH" },
  { value: "DURAR", label: "DURAR" },
  { value: "ELITE", label: "ELITE" },
  { value: "EMAAR", label: "EMAAR" },
  { value: "EQUITI", label: "EQUITI" },
  { value: "EXPO_CITY", label: "EXPO CITY" },
  { value: "IGO", label: "IGO (INVEST GROUP OVERSEAS)" },
  { value: "MARCUS", label: "MARCUS" },
  { value: "MUDONS", label: "MUDONS" },
  { value: "MYKA", label: "MYKA" },
  { value: "MYRA", label: "MYRA" },
  { value: "NABNI", label: "NABNI" },
  { value: "NAKHEEL", label: "NAKHEEL" },
  { value: "NSHAMA", label: "NSHAMA" },
  { value: "OCTA", label: "OCTA" },
  { value: "OMNIYAT", label: "OMNIYAT" },
  { value: "ORO_24", label: "ORO 24" },
  { value: "PURE_GOLD", label: "PURE GOLD" },
  { value: "REPORTAGE", label: "REPORTAGE" },
  { value: "SDS_TOWER", label: "SDS TOWER" },
  { value: "SHARJAH", label: "SHARJAH" },
  { value: "SLS", label: "SLS" },
  { value: "SOBHA", label: "SOBHA" },
  { value: "TIGER", label: "TIGER" },
  { value: "TIGER_PROPERTY", label: "TIGER PROPERTY" },
  { value: "WASL", label: "WASL" },
  { value: "SELECT_GROUP", label: "SELECT GROUP" },
  { value: "BNH", label: "BNH" },
  { value: "MAG", label: "MAG" },
  { value: "PROFILE", label: "PROFILE" },
  { value: "EMIRATES_PROPERTIES", label: "EMIRATES PROPERTIES" },
  { value: "Symbolic", label: "Symbolic" },
  { value: "Sustainable_City", label: "Sustainable City" },
];

export const paymentMode = [
  { value: "cash", label: "Cash" },
  { value: "cheque", label: "Cheque" },
  { value: "bank_tranfer", label: "Bank Transfer" },
];
export const paymentModeObj = {
  cash: "Cash",
  cheque: "Cheque",
  bank_tranfer: "Bank Transfer",
};

export const paymentStatus = [
  { value: "pending", label: "Pending" },
  { value: "received", label: "Received" },
  { value: "not_received", label: "Not Received" },
];

export const paymentStatusObj = {
  pending: "Pending",
  received: "Received",
  not_received: "Not Received",
};

export const token = [
  { value: true, label: "Paid" },
  { value: false, label: "UnPaid" },
];

export const bookingEntryStatus = [
  { value: "reject_timeout", label: "Rejected Time Out" },
  { value: "awaiting_documents", label: "Awaiting Deposit Documents" },
  { value: "executed", label: "Executed" },
  { value: "awaiting_token_payment", label: "Awaiting token Payment" },
  { value: "buyer_confirmation", label: "Buyer Confirmation" },
  { value: "spa_signed", label: "SPA signed by both parties" },
  { value: "rejected", label: "Rejected" },
];

export const bookingEntryStatusObj = {
  reject_timeout: "Rejected Time Out",
  awaiting_documents: "Awaiting Deposit Documents",
  executed: "Executed",
  awaiting_token_payment: "Awaiting token Payment",
  buyer_confirmation: "Buyer Confirmation",
  spa_signed: "SPA signed by both parties",
  rejected: "Rejected",
};

export const HrmsStatusObj = {
  new: "New",
  approved: "Approved",
  rejected: "Rejected",
  pending: "Pending",
  forwarded: "Forwarded",
  present: "Present",
  absent: "Absent",
  leave: "Leave",
  halfDay: "Half Day",
  cancel: "Cancel",
};

export const statusColor = {
  present: "#4ABC04",
  new: "#FFE500",
  absent: "#FF0000",
  leave: "#0095FF",
  approved: "#4ABC04",
  pending: "#0095FF",
  rejected: "#FF0000",
  forwarded: "#000080",
  halfDay: "#FFE500",
  cancel: "#C10404",
};

export const punchType = {
  office: "In-office",
  remote: "Remote",
  leave: "Leave",
};

export const visaType = {
  visit: "Visit",
  employment: "Employment",
  sponsor: "Sponsor",
};

export const relationType = {
  mother: "Mother",
  father: "Father",
  sister: "Sister",
  brother: "Brother",
  cousin: "Cousin",
  spouse: "Spouse",
  other: "Other",
};
export const relationOption = [
  { value: "father", label: "Father" },
  { value: "mother", label: "Mother" },
  { value: "brother", label: "Brother" },
  { value: "sister", label: "Sister" },
  { value: "cousin", label: "Cousin" },
  { value: "spouse", label: "Spouse" },
  { value: "other", label: "Other" },
];

export const activeStatus = [
  { value: "onboard", label: "On Board" },
  { value: "resign", label: "Resigned" },
  { value: "terminated", label: "Terminated" },
];

export const activeStatusObj = {
  onboard: "On Board",
  resign: "Resigned",
  terminated: "Terminated",
};

export const AttResolveObj = {
  true: "Resolved",
  false: "Not Resolved",
};
